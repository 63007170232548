'use client';
import { useContext, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { AdAllianceLib } from 'components/lib/AdAlliance/adAllianceLib';
import { predictionMinHeight } from 'components/lib/AdSizePrediction';
import { AppContext } from 'context/AppContext';
import { useAdDebug } from 'customHooks/useAdDebug';
import { AdSlotName, AdSlotType } from 'types/ads.d';
import type { PropsWithClassName } from 'types/react-props';
import { isMobile } from 'utils/detect';
import styles from './AdSlot.module.scss';
import { getAdslotName, keepName, shouldPreserveSpace } from './AdSlotHelper';
import { DefaultAd, OutbrainAd } from './componentsToRender';

export type AdSlotProps = PropsWithClassName<{
  readonly name: AdSlotName;
  readonly position?: number;
  readonly viewport?: AdSlotType;
  readonly outbrainWidgetId?: string;
  readonly horizontalAlign?: 'left' | 'center' | 'right';
  readonly skipPrediction?: boolean;
}>;

const preserveSpaceStatic = '90px';

export const NO_AD_AD_ZONE = '_noad';

const isOutbrain = (name: string) => name === AdSlotName.Outbrain || name === AdSlotName.OutbrainMobile;

const shouldPushAd = (
  name: string,
  fullAdSlotName: string,
  displayBasedOnViewport: boolean,
  pushedAdSlots: Array<string>
) =>
  !isOutbrain(name) &&
  displayBasedOnViewport &&
  !pushedAdSlots.includes(fullAdSlotName) &&
  name !== AdSlotName.Outstream;

export const AdSlot = ({
  name,
  position = 1,
  viewport = AdSlotType.Default,
  outbrainWidgetId,
  horizontalAlign = 'center',
  skipPrediction = false,
  className,
}: AdSlotProps) => {
  const {
    pageMetaData: { adZone, noAds },
    adSizePrediction,
    pushedAdSlots,
  } = useContext(AppContext);
  const isDebug = useAdDebug();
  const fullAdSlotName = keepName(name) ? name : `${name}_${position}`;

  const isForMobile = viewport === 'mobile';
  const matchedViewport: boolean = isMobile() === (viewport === AdSlotType.Mobile) || viewport === AdSlotType.Default;
  const [displayBasedOnViewport, setDisplayBasedOnViewport] = useState<boolean>(
    viewport === AdSlotType.Mobile || viewport === AdSlotType.Default
  );

  const [deviceOrientation, setDeviceOrientation] = useState<OrientationType>(
    typeof window !== 'undefined' ? window.screen.orientation?.type || 'portrait-primary' : 'portrait-primary'
  );

  if (typeof window !== 'undefined') {
    window.addEventListener('orientationchange', (event) => {
      setDeviceOrientation((event.target as Window).screen.orientation.type);
    });
  }

  useEffect(() => {
    setDisplayBasedOnViewport(matchedViewport);
  }, [matchedViewport, deviceOrientation]);

  const doPreserveSpace = shouldPreserveSpace(adZone, name, isForMobile, position);
  const showAdSizePrediction = !skipPrediction && adZone !== NO_AD_AD_ZONE;

  // AdAlliance relies on the existence of the following classname on the adSlot-wrapper
  const emsSelectorClassName = getAdslotName(viewport, fullAdSlotName, showAdSizePrediction, doPreserveSpace);

  useEffect(() => {
    if (noAds) {
      return;
    }

    if (shouldPushAd(name, fullAdSlotName, displayBasedOnViewport, pushedAdSlots)) {
      AdAllianceLib.pushAdSlot(fullAdSlotName as AdSlotName);
      pushedAdSlots.push(fullAdSlotName);
    }
  }, [name, noAds, fullAdSlotName, displayBasedOnViewport, pushedAdSlots]);

  if (noAds) {
    return null;
  }

  let minHeight = !skipPrediction
    ? predictionMinHeight({
        adSizePrediction,
        adSlotName: fullAdSlotName,
        adZone,
        isMobile: isForMobile,
      })
    : 'auto';

  if (doPreserveSpace) {
    minHeight = preserveSpaceStatic;
  }

  const classNames = clsx(emsSelectorClassName, styles[`${viewport}-viewport`], styles[horizontalAlign], className, {
    [styles.minHeightAuto]: minHeight === 'auto',
  });

  if (isDebug && displayBasedOnViewport) {
    return (
      <div
        className={clsx(classNames, styles.placeholder)}
        style={minHeight !== 'auto' ? { minHeight: `${minHeight}` } : {}}
        data-ada-container={`${fullAdSlotName}_${viewport}`}
      >
        Ad Slot: {fullAdSlotName}
        <br />
        type: {viewport}
        <br />
      </div>
    );
  }

  if (isOutbrain(name) && displayBasedOnViewport) {
    return (
      <OutbrainAd
        widgetId={outbrainWidgetId}
        className={clsx(styles.outbrainWrapper, styles[`${viewport}-viewport`], className)}
        fullAdSlotName={`${fullAdSlotName}_${viewport}`}
      />
    );
  }

  if (displayBasedOnViewport) {
    return (
      <DefaultAd
        className={classNames}
        minHeight={minHeight}
        adName={name}
        fullAdSlotName={fullAdSlotName}
        type={viewport}
      />
    );
  }

  return null;
};
