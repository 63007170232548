'use client';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useQueryParam } from 'customHooks/useQueryParam';
import { keepName } from './AdSlotHelper';

const OUTBRAIN_TEMPLATE = 'DE_RTL.de';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OBR?: any;
  }
}

export const DefaultAd = ({
  adName,
  className,
  minHeight,
  fullAdSlotName,
  type,
}: {
  adName: string;
  className: string;
  minHeight: string;
  fullAdSlotName: string;
  type: string;
}) => (
  <div className={className} style={minHeight !== 'auto' ? { minHeight: `${minHeight}` } : {}}>
    <div
      id={fullAdSlotName}
      data-device={type}
      className={keepName(adName) ? '' : 'gujAd'}
      data-ada-container={`${fullAdSlotName}_${type}`}
    />
  </div>
);

export const OutbrainAd = ({
  widgetId = 'SF_5',
  className,
  fullAdSlotName,
}: {
  widgetId?: string;
  className?: string;
  fullAdSlotName?: string;
}) => {
  const pathName = usePathname();
  const device = useQueryParam('device');
  const [userAdId, setUserAdId] = useState('null');

  const fullPath = `https://www.rtl.de${pathName}`;
  const isSmartfeed = widgetId === 'AR_10' || widgetId === 'AR_20';

  useEffect(() => {
    const adId = document.cookie
      .split('; ')
      .find((row) => row.startsWith('AdId='))
      ?.split('=')[1];
    setUserAdId(adId ?? 'null');

    window?.OBR && window.OBR.extern.researchWidget();
  }, []);

  if (isSmartfeed && !!device && ['android', 'iOS'].includes(device)) {
    return (
      <div className={className} data-ada-container={fullAdSlotName} data-ob-identifier={`ad-outbrain-${widgetId}`}>
        <div
          className="OUTBRAIN"
          data-src={fullPath}
          data-widget-id={device === 'iOS' ? 'MB_1' : 'MB_11'}
          data-ob-user-id={userAdId}
          data-ob-installation-key={device === 'iOS' ? 'DERTL2DL5GFLPIBD78HOPEBA6' : 'DERTL2I6IBI3O1OCGN7EMP9C3'}
        />
      </div>
    );
  }

  return (
    <div className={className} data-ada-container={fullAdSlotName} data-ob-identifier={`ad-outbrain-${widgetId}`}>
      <div className="OUTBRAIN" data-src={fullPath} data-widget-id={widgetId} data-ob-template={OUTBRAIN_TEMPLATE} />
    </div>
  );
};
