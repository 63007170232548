'use context';
import { useContext } from 'react';
import type { SlideImage } from 'yet-another-react-lightbox';
import { useLightboxState } from 'yet-another-react-lightbox';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import Image from '../Image/Image';
import { Typography } from '../Typography';
import { VideoPlayerControlsLoading } from '../VideoPlayerControls/VideoPlayerControlsLoading';

export const GallerySlide = ({ slide }: { slide: SlideImage }) => {
  const state = useLightboxState();

  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh ? require('./GalleryNew.module.scss') : require('./Gallery.module.scss');

  if (slide.loading) {
    return <VideoPlayerControlsLoading />;
  }

  return (
    <div className={styles.slide}>
      <Image image={slide.image} alt={slide.title} />
      <div className={styles.slideInfo}>
        <Typography variant="4xs" className={styles.slideCounter} marginBottom={'03'} textAlign="center">
          {slide.count} von {state.slides.length}
        </Typography>
        <Typography variant="3xs" className={styles.slideTitle} marginBottom={'03'} textAlign="center">
          {(slide.fields.subtitle as string) ?? slide.title}
        </Typography>
        <Typography variant="4xs" className={styles.slideCredits} textAlign="center">
          {(slide.fields.copyright as string) ?? 'RTL'}
        </Typography>
      </div>
    </div>
  );
};
