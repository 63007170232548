import { Environment, type MediaConfig } from '@foundation-player/loader';
import type { PlayerLoaderConfig, UIConfig } from '@foundation-player/ui';
import { getVpShare } from 'utils/ads';
import { getSeoTitle } from '../SchemaObjects/helper/utils';
import { getVideoDuration, getVideoEncoding } from '../VideoPlayer/helper/utils';
import type { VideoProps, VideoTags } from '../VideoPlayer/VideoPlayerDefault';

type CreateMediaConfigProps = {
  ads?: boolean;
  ivwCode: string;
  postRoll?: boolean;
  poster?: string;
  tcString: string;
  video: VideoProps;
  autoplay?: boolean;
  moltenVideoTracking: string;
};

export type SlotType = 'pre' | 'mid' | 'post';

const createAdCall = (
  id: string,
  conlen: number,
  slot: SlotType,
  tcString: string,
  ivw: string,
  moltenVideoTracking: string
): string => {
  const params = new URLSearchParams({
    type: 'vast4',
    fwd_adb: '0',
    s: getVpShare(ivw),
    sz: slot,
    tagver: '6.1.1',
    fwd_comp: '0',
    ang_cntp: 'none',
    ang_streamid: crypto.getRandomValues(new Uint32Array(1)).toString(),
    ang_conlen: Math.round(conlen).toString(),
    fwd_conid: id,
    rnd: crypto.getRandomValues(new Uint32Array(1)).toString(),
    ang_ref: encodeURI(window?.location?.href ?? 'https://www.rtl.de'),
  }).toString();

  return `https://ad-ipd.sxp.smartclip.net/select?${params}&${moltenVideoTracking}&consent=${tcString}`;
};

export const createUiConfig = (poster?: string): UIConfig => ({
  poster: poster,
  allowNativeVideoFullscreen: true,
});

export const createPlayerLoaderConfig = (
  consentString: string,
  ads: boolean = true,
  env: Environment = 'dev',
  version: string = '0.0.1'
): PlayerLoaderConfig => ({
  client: 'rtldigitalnews',
  scope: 'rtl',
  environment: env,
  consentString: consentString,
  pluginConfigs: {
    'foundation.plugin.advertising': {
      enabled: ads,
      smartclipConfig: {
        adReinsertion: {
          homad: {
            enabled: true,
            setup: {
              clientConfig: 'https://cdn.static-fra.de/now/sc/rtl.de.clientConfig.json',
            },
          },
        },
      },
    },
    'foundation.plugin.googleAnalytics4': {
      enabled: true,
      progressTrackingIntervalInSeconds: 9999,
    },
    'foundation.plugin.heartbeat': {
      enabled: true,
      endpoint: {
        vod: 'https://px1.vtrtl.de/vt/hb.do',
        live: 'https://px1.vtrtl.de/vt/hb.do',
      },
      isKidsProfile: false,
      offer: 'rtl',
      payStatus: 0,
      userStatus: 0,
      videoService: 'vms',
    },
    'foundation.plugin.npawAnalytics': {
      enabled: true,
      appName: 'rtl',
      appVersion: version,
      userType: 'free',
    },
    'foundation.plugin.nielsen': {
      enabled: true,
    },
  },
});

export const createMediaConfig = ({
  ads = true,
  ivwCode,
  postRoll = true,
  poster = 'https://www.rtl.de/fallbackImage.jpg',
  tcString,
  video,
  autoplay = false,
  moltenVideoTracking,
}: CreateMediaConfigProps): MediaConfig => {
  const { fields, sourceId } = video;
  const videoDuration = getVideoDuration(fields.encoding);
  const title = decodeURIComponent(fields.title);

  return {
    advertising: {
      slots: [
        {
          adCall: ads
            ? createAdCall(sourceId, videoDuration, 'pre', tcString, ivwCode, moltenVideoTracking)
            : undefined,
          offset: 0,
          type: 'LINEAR',
        },
        {
          adCall:
            ads && videoDuration > 480
              ? createAdCall(sourceId, videoDuration, 'mid', tcString, ivwCode, moltenVideoTracking)
              : undefined,
          offset: 180,
          type: 'LINEAR',
        },
        {
          adCall:
            ads && postRoll && videoDuration > 30
              ? createAdCall(sourceId, videoDuration, 'post', tcString, ivwCode, moltenVideoTracking)
              : undefined,
          offset: -1,
          type: 'LINEAR',
        },
      ],
    },
    autoplay: autoplay,
    id: sourceId,
    manifests: [
      {
        type: 'progressive',
        sources: [
          {
            url: getVideoEncoding(fields.encoding)?.value['content-url'].value ?? '',
            priority: 'main',
          },
        ],
      },
      {
        type: 'dashhd',
        sources: [
          {
            url: getVideoEncoding(fields.encoding, 'dash')?.value['content-url'].value ?? '',
            priority: 'fallback',
          },
        ],
      },
      {
        type: 'hlsfairplayhd',
        sources: [
          {
            url: getVideoEncoding(fields.encoding, 'hls')?.value['content-url'].value ?? '',
            priority: 'fallback',
          },
        ],
      },
    ],
    metadata: {
      title: title,
      poster: poster,
      headline: title,
      secondaryHeadline: decodeURIComponent(fields?.subtitle ?? ''),
      firstPublicationDate: video.createdAt ?? '',
      lastPublicationDate: video.lastmodifiedAt ?? '',
      userStarted: true,
    },
    plugins: {
      'foundation.plugin.heartbeat': {
        id: sourceId,
        ivw: ivwCode,
        startType: 0,
      },
      'foundation.plugin.npawAnalytics': {
        title: title,
        userType: 'free',
        channelName: 'rtl',
      },
      'foundation.plugin.nielsen': {
        commentPath: ivwCode,
        id: sourceId,
      },
      'foundation.plugin.googleAnalytics4': {
        firstHeadline: getSeoTitle(fields['first-title'], fields['first-subtitle']),
        headline: getSeoTitle(fields.title, fields.subtitle),
        tags: video.tags?.map((item: VideoTags) => item.name),
        firstPublicationDate: video.publishedAt ?? undefined,
        lastPublicationDate: video.lastmodifiedAt ?? undefined,
        contentType: 'video',
      },
    },
    type: 'vod',
  };
};
