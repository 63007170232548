'use client';

import { useContext } from 'react';
import Image from 'components/UI/Image/Image';
import Link from 'components/UI/Link/Link';
import { Logo } from 'components/UI/Logo';
import type { TitleLink } from 'components/UI/Title/Title';
import Title from 'components/UI/TitleNew/Title';
import { AppContext } from 'context/AppContext';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { linkUrl } from 'utils/url';
import styles from './widget-teaser-highlight.module.scss';

export type WidgetTeaserHighlightProps = {
  readonly model: {
    readonly fields: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly headline?: string;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserHighlight = ({ context }: { context: WidgetTeaserHighlightProps }) => {
  const width = 138;
  const { featureFlags } = useContext(AppContext);
  const enabled = isFeatureFlagEnabled('ft_new_highlight_teaser', featureFlags);

  const isRebrush = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  if (!enabled) {
    return null;
  }

  return (
    <section>
      <Title>{context.model.fields.title}</Title>
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          {context.items?.slice(0, 4).map((item) => (
            <Link
              href={linkUrl(item.model.fields.externalUrl ?? item.model.url)}
              key={item.model.id}
              className={styles.teaser}
            >
              <Image
                image={item.model.image}
                className={styles.image}
                alt={`Bild zu: "${item.model.fields.title}"`}
                title={item.model.fields.title}
                width={width}
                cropOverride="c1_1"
              />
              <div className={styles.text}>
                {isRebrush &&
                  (item.model.themenwelt ? (
                    <Logo size="tiny" colors={item.model.themenwelt.logo_colors ?? ['#2a08b5', '#6f0bed', '#ec33ec']} />
                  ) : (
                    <Logo size="tiny" variant="rtlDe" />
                  ))}
                <div>{item.model.fields.title}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WidgetTeaserHighlight;
