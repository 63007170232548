'use client';
import { useContext } from 'react';
import Image, { type ApiImageProps } from 'components/UI/Image/Image';
import Link from 'components/UI/Link/Link';
import { TeaserGridSection } from 'components/UI/TeaserGridSection';
import type { TitleLink } from 'components/UI/Title/Title';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { getSizes } from 'utils/image';

export type WidgetTeaserRtlPlusProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<{
    readonly model: {
      readonly id: string;
      readonly fields: {
        readonly title: string;
        readonly url: string;
      };
      readonly image?: ApiImageProps;
    };
  }>;
};

export const isRtlPlusType = (type: string): boolean => type === 'rtl-plus-episodes' || type === 'rtl-plus-series';

const WidgetTeaserRtlPlus = ({ context }: { context: WidgetTeaserRtlPlusProps }) => {
  const width = 360;
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-teaser-rtl-plus-new.module.scss')
    : require('./widget-teaser-rtl-plus.module.scss');

  return (
    <div className={styles.background}>
      <TeaserGridSection
        title={context.model?.fields?.title}
        titleLink={context.model?.fields?.link}
        isRtlPlus
        anchor={context.model?.fields?.anchor}
      >
        {context.items?.map((item) => (
          <div className={styles.teaser} key={item.model.id}>
            <div className={styles.imageWrapper}>
              <Image
                image={item.model.image}
                className={styles.image}
                width={width}
                alt={`Bild zu: "${item.model.fields.title}"`}
                sizes={getSizes('defaultTeaser')}
              />
            </div>
            <Link className={styles.link} href={item.model.fields.url || ''} target="_blank" noFollow={false}>
              <div className={styles.textWrapper}>
                <h3 className={styles.h3}>
                  <Typography className={styles.headline} as="div" variant="s">
                    {item.model.fields.title}
                  </Typography>
                </h3>
              </div>
            </Link>
          </div>
        ))}
      </TeaserGridSection>
    </div>
  );
};

export default WidgetTeaserRtlPlus;
